import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CotizacionesProvider } from './hooks/useCotizaciones';
import Layout from './components/Layout';

const Home = lazy(() => import('./pages/Home'));
const Cotizaciones = lazy(() => import('./pages/Cotizaciones'));
const Tenencias = lazy(() => import('./pages/Tenencias'));
const TerminosYCondiciones = lazy(() => import('./pages/TerminosYCondiciones'));
const PoliticaDePrivacidad = lazy(() => import('./pages/PoliticaDePrivacidad'));

function App() {
  return (
    <CotizacionesProvider>
      <Router>
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cotizaciones" element={<Cotizaciones />} />
              <Route path="/tenencias" element={<Tenencias />} />
              <Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
              <Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />} />
              {/* Ruta comodín para redirigir cualquier ruta no encontrada al home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </CotizacionesProvider>
  );
}

export default App;