import React from 'react';
import { Twitter, Instagram, Mail, Send } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Array de enlaces sociales
  const socialLinks = [
    { icon: Twitter, url: process.env.REACT_APP_TWITTER, label: 'Twitter' },
    { icon: Instagram, url: process.env.REACT_APP_INSTAGRAM, label: 'Instagram' },
    { icon: Mail, url: `mailto:${process.env.REACT_APP_EMAIL}`, label: 'Mail' },
    { icon: Send, url: process.env.REACT_APP_TELEGRAM, label: 'Telegram' },
  ];

  return (
    <footer className="bg-white shadow-sm mt-auto w-full">
      <div className="w-full px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col items-center space-y-4">
          {/* Texto de derechos reservados */}
          <p className="text-gray-600 text-center">
            &copy; {currentYear} MFinanzas. Todos los derechos reservados.
          </p>

          {/* Enlaces a Términos y Condiciones y Política de Privacidad */}
          <div className="flex space-x-4">
            <a
              href="/terminos-y-condiciones"
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              Términos y Condiciones
            </a>
            <a
              href="/politica-de-privacidad"
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              Política de Privacidad
            </a>
          </div>

          {/* Enlaces sociales */}
          <div className="flex space-x-4">
            {socialLinks.map(({ icon: Icon, url, label }) => (
              <a
                key={label}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                aria-label={label}
                title={url}
              >
                <Icon size={20} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;