import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Twitter,Send,Mail, Instagram, Coffee, Menu } from 'lucide-react';
import Footer from './Footer';

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const socialLinks = [
    { icon: Twitter, url: process.env.REACT_APP_TWITTER, label: 'Twitter' },
    { icon: Instagram, url: process.env.REACT_APP_INSTAGRAM, label: 'Instagram' },
    { icon: Mail, url: `mailto:${process.env.REACT_APP_EMAIL}`, label: 'Mail' }, // Cambia aquí
    { icon: Send, url: process.env.REACT_APP_TELEGRAM, label: 'Telegram' }

  ];

  return (
    <div className="flex flex-col min-h-screen w-full bg-gray-100">
      <nav className="bg-white shadow-sm w-full">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl font-bold text-gray-800">
                MFinanzas
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex md:items-center md:space-x-8">
              {['cotizaciones', 'tenencias'].map((route) => (
                <Link
                  key={route}
                  to={`/${route}`}
                  className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium transition-colors duration-200 ease-in-out
                    ${isActive(`/${route}`)
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    }`}
                >
                  {route.charAt(0).toUpperCase() + route.slice(1)}
                </Link>
              ))}
            </div>

            {/* Social Links and Donate Button */}
            <div className="hidden md:flex md:items-center md:space-x-4">
            {socialLinks.map(({ icon: Icon, url, label }) => (
  <a
    key={label}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
    aria-label={label}
    title={url}
  >
    <Icon size={20} />
  </a>
))}
              <a
                href="https://matecito.co/MFinanzas"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
              >
                <Coffee size={18} className="mr-2" />
                Donar
              </a>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <Menu size={24} />
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {['cotizaciones', 'tenencias'].map((route) => (
                  <Link
                    key={route}
                    to={`/${route}`}
                    className={`block pl-3 pr-4 py-2 text-base font-medium ${
                      isActive(`/${route}`)
                        ? 'text-blue-600 bg-blue-50'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {route.charAt(0).toUpperCase() + route.slice(1)}
                  </Link>
                ))}
                <div className="flex items-center space-x-4 px-3 py-2">
                  {socialLinks.map(({ icon: Icon, url, label }) => (
                    <a
                      key={label}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700"
                      aria-label={label}
                    >
                      <Icon size={20} />
                    </a>
                  ))}
                  <a
                    href="https://matecito.co/MFinanzas"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Coffee size={18} className="mr-2" />
                    Donar
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>

      <main className="flex-grow w-full">
        <div className="w-full px-4 sm:px-6 lg:px-8 py-6">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Layout;

